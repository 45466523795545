@import 'assets/fonts/_fonts.scss';
@import '~@mdi/font/css/materialdesignicons.css';
/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

$primary-color: #21698f;
$secondary-color: #8f2146;
$text-color: black;
$nav-height: 56px;
$alert-height: 56px;
$footer-height: 28px;
$sidebar-width: 188px;

.color-primary {
    color: $primary-color !important;
}

a {
    cursor: pointer;
    color: $text-color;
    &:hover {
        color: $text-color;
        text-decoration: none;
    }
    &:focus {
        color: lighten($primary-color, 10%);
        text-decoration: underline;
    }
}

.btn-group {
    border-radius: 5px;
    box-shadow: 0px 1px 1px rgba(black, 0.2) !important;
    > .btn {
        box-shadow: none !important;
    }
}

.btn, .navbar-toggler {
    border-radius: 5px;
    border: 1px solid rgba(black, 5%) !important;
    box-shadow: 0px 1px 1px rgba(black, 20%) !important;
    &.btn-primary {
        background: none;
        &:focus {
            border: 1px solid rgba(black, 25%) !important;
            background: rgba(white, 10%) !important;
        }
        &.active {
            // border: 1px solid rgba(black, 5%) !important;
            background: rgba(white, 10%) !important;
        }
    }
    &.btn-link {
        color: $text-color;
        border: none !important;
        box-shadow: none !important;
        background: none !important;
        &:hover {
            text-decoration: none;
        }
        &:focus {
            background: lighten($primary-color, 5%);
            border: none !important;
            color: lighten($primary-color, 10%);
        }
    }
    &:focus {
        outline: none;
        background: lighten($primary-color, 10%);
        // border: 1px solid rgba(black, 0.25) !important;
        // &.btn-primary {
        //     background: lighten($primary-color, 15%);
        // }
        // &.btn-link {
        //     border: none !important;
        //     color: lighten($primary-color, 10%);
        // }
    }
}

.input-group {
    // background: white;
    // border-radius: 5px 5px !important;
    color:black ;
    .input-group-prepend {
        padding-top: 0.075rem;
        padding-bottom: 0.075rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        margin-right: -2.25rem;
        z-index: 1;
    }
    .form-control {
        // background: none;
        padding-left: 2.25rem;
    }
}

.form-control {
    border-radius: 5px 5px !important;
    background: white;
    border: 1px solid lighten($primary-color, 55%);
    box-shadow: 0px 1px 1px inset rgba(black, 0.2);
    &:focus {
        // box-shadow: none !important;
        box-shadow: 0px 1px 1px inset rgba(black, 0.2) !important;
        border: 1px solid lighten($primary-color, 20%) !important;
        z-index: 0 !important;
    }
}

input[type="file"] {
    padding: 4px;
}

a:focus {   
    border-color: rgba(black, 0.1) !important;
    outline: none;
}

// .form-check-input:focus {
//     outline: 1px solid lighten($primary-color, 20%) !important;
// }

.card {
    border: none;
    box-shadow: 0px 1px 1px 0px rgba(black, 0.2);
    border-radius: 5px;
}

.card-header {
    background: lighten($primary-color, 10%);
    border-radius: 5px 5px 0px 0px !important;
    border: 1px solid rgba(black, 0.05) !important;
    border-bottom: none !important;
    color: white;
    z-index: 1;
    & ~ .card-body {
        border-radius: 0px 0px 5px 5px;
        border-top: none;    
    }
    .btn-link {
        color: white;
    }
    .form-control {
        border: 1px solid lighten($primary-color, 15%);
        &:focus {
            border: 1px solid $primary-color;
            z-index: 0;
        }
    }
}

.card-body {
    background: lighten($primary-color, 60%);
    border-radius: 5px;
    border: 1px solid rgba(black, 0.05) !important;
    z-index: 0;
    .btn-primary {
        background: lighten($primary-color, 20%);  
        &:focus {
            border: 1px solid rgba(black, 0.25) !important;
            background: lighten($primary-color, 30%) !important;
        }
        &.active {
            // border: 1px solid rgba(black, 0.25) !important;
            background: lighten($primary-color, 30%) !important;
        }     
    }
    & .card-header {
        background: lighten($secondary-color, 60%) !important;
        & .form-control {
            border: 1px solid lighten($secondary-color, 55%) !important;
            &:focus {
                border: 1px solid $secondary-color !important;
                z-index: 0 !important;
            }
        }
        & .btn-primary {
            color: black;
        }
    }
    & .card {
        box-shadow: none !important;
    }
}

html {
    position: relative;
    min-height: 100%;
    width: 100%;
}

body {
    font-family: 'Inter';
    font-weight: 500;
}

.mat-ripple { 
    display: none; 
}

.mat-checkbox {
    font-family: "Inter" !important;
}

.mat-checkbox-input {
    opacity: 0;
}

.mat-checkbox-frame {
    background: white !important;
    border: 1px solid lighten($primary-color, 55%) !important;
    box-shadow: 0px 1px 1px inset rgba(black, 0.2) !important;
    border-radius: 5px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: lighten($primary-color, 10%);
    border-color: lighten($primary-color, 5%);
    border-radius: 5px !important;
}

.mat-radio-button {
    font-family: 'Inter' !important;
    font-weight: 500;
}

.mat-radio-outer-circle {
    border-width: 1px !important;
    border-color: lighten($primary-color, 55%) !important;
    background: white;    
    box-shadow: 0px 1px 1px inset rgba(black, 0.2) !important;
}

.mat-radio-inner-circle {
    top: 1px !important;
    background-color: lighten($primary-color, 10%) !important;
    border-color: lighten($primary-color, 5%);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.mat-input-element {
    @extend .form-control;
    @extend .mr-1;
    width: calc(100% - 46px);
    display: inline;
}

.mat-datepicker-toggle {
    @extend .btn;
    @extend .p-0;
    > .mat-icon-button {
        height: 37px;        
    }
    :focus {
        outline: none;
        background: lighten($primary-color, 60%) !important;
        border-radius: 0 !important;
    }
}

.custom-select {
    border-radius: 5px 5px !important;
    border: 1px solid lighten($primary-color, 55%);
    box-shadow: 0px 1px 1px inset rgba(black, 0.2) !important;
    &:focus {
        box-shadow: 0px 1px 1px inset rgba(black, 0.2) !important;
        border: 1px solid lighten($primary-color, 20%) !important;
        z-index: 0 !important;
    }
}

mat-calendar {
    font-family: 'Inter' !important;
    font-weight: 500;
}

mat-datepicker-content {
    @extend .card;
    margin-top: 4px !important;
}

input[type=checkbox]:focus + div[matripple] + div.mat-checkbox-frame {
    border: 1px solid lighten($primary-color, 20%) !important;
}

input[type=checkbox]:focus {
    outline: none;
}

// .form-control:disabled, input:disabled{
//     background: lighten($primary-color, 62%) !important;
// }

a > .mdi, button > .mdi {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cdk-overlay-pane {
    max-width: 100vw !important;
}

.cdk-overlay-container {
    z-index: 1020 !important;
}

.cdk-global-overlay-wrapper {
    overflow-y: auto;
}

::ng-deep mat-dialog-container {
    @media (max-width: 575.98px) { 
        max-width: 100%;
    }

    // Small devices (landscape phones, 576px and up)
    @media (min-width: 576px) and (max-width: 767.98px) { 
        max-width: 540px;
    }

    // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) and (max-width: 991.98px) { 
        max-width: 720px;
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) and (max-width: 1199.98px) { 
        max-width: 960px;
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) { 
        max-width: 1140px;
    }
}

mat-dialog-container {
    // @extend .card;
    width: 100vw !important;
    padding: 3px !important;
    background: transparent !important;
    box-shadow: none !important;
}

::ng-deep fieldset[disabled] input, 
::ng-deep fieldset[disabled] textarea, 
::ng-deep fieldset[disabled] .mat-input-element, 
::ng-deep fieldset[disabled] select,
::ng-deep fieldset[disabled] .mat-radio-outer-circle,
::ng-deep fieldset[disabled] .mat-checkbox-frame,
::ng-deep fieldset[disabled] ngx-timepicker-field,
::ng-deep fieldset[disabled] .age-picker,
::ng-deep fieldset[disabled] input-time {
    border: none !important;
    box-shadow: none !important;
    font-weight: 600;
    background-color: white !important;
}

::ng-deep fieldset[disabled] input-time * {
    background: transparent !important;
}

::ng-deep fieldset[disabled] textarea {
    overflow: hidden;
    resize: none;
}

// ::ng-deep fieldset[disabled] .datatable-body {
//     // overflow: hidden !important;
// }

::ng-deep fieldset[disabled] span.sort-btn,
::ng-deep fieldset[disabled] mat-datepicker-toggle {
    display: none !important;
}

::ng-deep fieldset[disabled] .mat-input-element {
    width: 100%;
}

::ng-deep fieldset[disabled] select::-ms-expand {
    display: none !important;
}

::ng-deep fieldset[disabled] input[type=file]::-webkit-file-upload-button {
    visibility: hidden !important;
    width: 0.25rem !important;
}

::ng-deep fieldset[disabled] select {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
}

.mat-chip-list-wrapper {
    @extend .form-control;
    height: auto !important;
    padding: 0px !important;
    & input {
       border: none;
       box-shadow: none; 
    }
}

.subnav-panel {
    @extend .card-header;
    border-radius: 0px !important;
    border-bottom: 1px solid lighten($primary-color, 15%) !important;
    box-shadow: 0px 1px 1px rgba(black, 0.2) !important;
    color: black !important;
}

mat-datepicker-toggle {
    position: absolute;
}

.ngx-timepicker {
    border: none !important;
}

.ngx-timepicker--disabled {
    background: none !important;
}

@media screen {
    .print-header, .print-table-body {
        display: none !important;
    }
}

.custom-tooltip-blue{
    font-size: medium !important;
    font-family: "Inter" !important;
}

.mat-tree {
    @extend .form-control;
    font-family: 'Inter' !important;
    font-size: 1rem !important;
    height: 200px;
    overflow-y: scroll;
}

.mat-tree-node {    
    font-size: 1rem !important;
}

// @media print {    
//     @page { 
//         size: A4 portrait; 
//         margin: 0;
//     }

//     .print-header, .print-header *, .print-body, .print-body *, .print-table-body, .print-table-body * {
//         visibility: visible !important;
//         color: black !important;
//     }

//     .print-header, .print-body, .print-table-body, .print-parent {
//         position: absolute;
        
//         left: 0px !important;
//         width: 100vw !important; 
//         // width: 133.33% !important; 
//         border: none !important;
//         box-shadow: none !important;
//         border-radius: 0px !important;
//         // transform: scale(0.75,0.75);
//         // transform-origin: top left;
//         max-width: unset !important;
//         max-height: unset !important;
//     }

//     .print-parent {
//         visibility: hidden !important;
//         background: transparent !important;
        
//         height: 100% !important;
//         left: 0px !important;

//         padding: 0px !important;
//         margin: 0px !important;
//     }

//     body {
//         width: 100vw !important;
//         height: 100vh !important;
//     }

//     body *:not(.print-parent, .print-header, .print-header *, .print-body, .print-body *, .print-table-body, .print-table-body *) {
//         display: none !important;
//     }

//     // mat-drawer, .sidebar-toogle {
//     //     display: none !important;
//     // }

//     // mat-drawer-container, mat-drawer-content {
//     //     width: 100vw !important;
//     // }

//     // mat-drawer-content {
//     //     margin-left: 0px !important;   
//     // }

//     // .mat-dialog-container {
//     //     overflow: unset !important;
//     //     position: absolute !important;
//     //     left: 0 !important;
//     //     top: 0 !important;
//     //     max-width: unset !important;
//     // }

//     // .cdk-overlay-container {
//     //     position: absolute !important;
//     // }
    
//     // .cdk-global-overlay-wrapper {
//     //     overflow-y: unset !important;
//     // }

//     // .cdk-overlay-container {
//     //     position: absolute !important;
//     // }

//     .print-table-body ngx-datatable datatable-body-cell,
//     .print-table-body ngx-datatable datatable-header-cell {
//         border: 1px solid black !important;
//     }

//     .print-table-body ngx-datatable datatable-header,
//     .print-table-body ngx-datatable datatable-body {
//         width: 100% !important;
//         background: red !important;
//     }

//     .print-table-body ngx-datatable datatable-header-cell:first-child,
//     .print-table-body ngx-datatable datatable-body-cell:first-child,
//     .print-table-body ngx-datatable .sort-btn {
//         display: none !important
//     }

//     .print-table-body ngx-datatable datatable-body {
//         overflow: hidden !important;
//     }

//     ::-webkit-scrollbar {
//         display: none;
//     }

//     app-selector {
//         display: none !important;
//     }
    
//     fieldset[disabled] .form-control, 
//     fieldset[disabled] input[matInput],
//     fieldset[disabled] .mat-radio-container .mat-radio-outer-circle,
//     fieldset[disabled] .mat-checkbox-frame,
//     fieldset[disabled] .mat-checkbox-background,    
//     fieldset .form-control, 
//     fieldset input[matInput],
//     fieldset .mat-radio-container .mat-radio-outer-circle,
//     fieldset .mat-checkbox-frame,
//     fieldset .mat-checkbox-background,
//     .seal-box{
//         border: 1px solid black !important;
//     }

//     fieldset textarea {
//         overflow: hidden;
//         resize: none;
//     }

//     fieldset input[type=file]::-webkit-file-upload-button {
//         visibility: hidden !important;
//         width: 0.25rem !important;
//     }

//     fieldset[disabled] app-table .card {
//         width: 100% !important;
//         height: auto !important;
//         border: 1px solid black !important;
//         padding: 0.75rem !important;
//         * {
//             display: none !important;
//         }
//     }

//     fieldset[disabled] app-table .card::after {
//         content: 'In separate document';
//     }

//     fieldset[disabled] .mat-radio-inner-circle,
//     fieldset .mat-radio-inner-circle {
//         border: 10px solid black !important;
//     }

//     fieldset[disabled] .mat-checkbox-checked .mat-checkbox-background,
//     fieldset .mat-checkbox-checked .mat-checkbox-background {
//         border: 8px solid black !important;
//     }

//     .flex-lg-row {
//         flex-direction: row !important;
//     }

//     .d-lg-block {
//         display: block !important;
//     }

//     .badge {
//         border: none;
//     }

//     .form-header {
//         border: none !important;
//         box-shadow: none !important
//     }

//     fieldset select::-ms-expand {
//         display: none !important;
//     }

//     fieldset select {
//         -moz-appearance: none;
//         -webkit-appearance: none;
//     }

//     .btn, a {
//         display: none;
//     }
// }